html {
  background-color: #0096ed;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
}

.App {
  position: absolute;
  left: 0;
  top: 0;
}

html::-webkit-scrollbar {
  display: none;
}

/* ---------------------------------------- */
/* ----------- Universal Styling ---------- */
/* ---------------------------------------- */
.no-margins {
  margin: 0;
}

.page-wrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -50;
}

.mobile-page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 95vh;
  z-index: -50;
}

.decoration-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

/* ---------------------------------------- */
/* ------------ Desktop Navbar ------------ */
/* ---------------------------------------- */
.navbar-wrapper {
  top:0;
  position: fixed;
  display: flex;
  float: left;
  height: 100vh;
  transition: width 0.75s;
  pointer-events: none;
}

.navbar-content-wrapper {
  height: 100%;
  background-color: #006fed;
  transition: width 0.75s;
  pointer-events: all;
}

.navbar-content {
  position: relative;
  overflow: hidden;
  color: #f2f9ff;
  padding-left: 9%;
  z-index: 100;
  transition:opacity 0.25s;
}

.navbar-toggle {
  position: relative;
  width: 20vw;
  background-color: #006fed;
  transform: skew(-8deg) translate(-9vw);
  -webkit-box-shadow: 10px 3px 5px 0px rgba(63, 63, 63, 0.75);
  -moz-box-shadow: 10px 3px 5px 0px rgba(63, 63, 63, 0.75);
  box-shadow: 10px 3px 5px 0px rgba(63, 63, 63, 0.75);
  z-index: 51;
  padding-top: 4vh;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  pointer-events: all;
}

@-webkit-keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateX(0) skew(-8deg) translate(-9vw);} 
  40% {-webkit-transform: translateX(2vw) skew(-8deg) translate(-9vw);} 
  60% {-webkit-transform: translateX(1vw) skew(-8deg) translate(-9vw);} 
} 

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateX(0) skew(-8deg) translate(-9vw);} 
  40% {transform: translateX(2vw) skew(-8deg) translate(-9vw);} 
  60% {transform: translateX(1vw) skew(-8deg) translate(-9vw);} 
}

.bounce { 
  -webkit-animation-name: bounce; 
  animation-name: bounce; 
}

.navbar-whitespace {
  position: absolute;
  right: 0;
  height: 100%;
  pointer-events: all;
}

.navbar-title {
  margin-top: 3vh;
  font-size: 38px;
  text-shadow: 2px 2px 0 #7A7A7A;
}

.navbar-link {
  white-space: nowrap;
  margin-top: 20px;
  text-decoration: none;
  color: #f2f9ff;
  font-size: 26px;
  text-shadow: 2px 2px 0 #7A7A7A;
}

.navbar-social-link {
  margin-top: 20px;
  margin-right: 5px;
  text-decoration: none;
  color: #f2f9ff;
} 

.navbar-link-icon {
  width: 20px;
  height: auto;
}

.navbar-social-links {
  position:fixed;
  bottom:2%;
}

.navbar-closed-icons {
  font-size: 28px;
  position:fixed;
  color:#f2f9ff;
  transform:skew(8deg);
}

/* ---------------------------------------- */
/* ------------ Mobile Navbar ------------- */
/* ---------------------------------------- */
.mobile-navbar-content {
  height: 100%;
  position: relative;
  overflow: hidden;
  color: #f2f9ff;
  padding-left: 9%;
  z-index: 100;
  transition:opacity 0.25s;
  -webkit-box-shadow: 10px 3px 5px 0px rgba(63, 63, 63, 0.75);
  -moz-box-shadow: 10px 3px 5px 0px rgba(63, 63, 63, 0.75);
  box-shadow: 10px 3px 5px 0px rgba(63, 63, 63, 0.75);
  pointer-events: all;
}

.mobile-navbar-toggle {
  margin-top: 4vh;
  position: relative;
  width: 50vw;
  height: 10vw;
  background-color: #006fed;
  -webkit-box-shadow: 5px 3px 5px 0px rgba(63, 63, 63, 0.75);
  -moz-box-shadow: 5px 3px 5px 0px rgba(63, 63, 63, 0.75);
  box-shadow: 5px 3px 5px 0px rgba(63, 63, 63, 0.75);
  z-index: -100;
  -webkit-animation-duration: 1s;
  animation-duration: 1s; 
  transition: width 0.75s;
  border-radius: 3px;
  pointer-events: all;
}

@-webkit-keyframes mobile-bounce { 
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateX(0);} 
  40% {-webkit-transform: translateX(5px);} 
  60% {-webkit-transform: translateX(5px);} 
} 

@keyframes mobile-bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateX(0);} 
  40% {transform: translateX(5px);} 
  60% {transform: translateX(5px);} 
}

.mobile-bounce { 
  -webkit-animation-name: mobile-bounce; 
  animation-name: mobile-bounce; 
}

.mobile-navbar-link {
  white-space: nowrap;
  margin-top: 20px;
  text-decoration: none;
  color: #f2f9ff;
  font-size: calc(14px + 2vmin);
}

.mobile-navbar-hamburger {
  position:absolute;
  height:100%;
  right:1.5vw;
  color:#f2f9ff;
  font-size:8vw;
}

.mobile-navbar-social-links {
  display:flex;
  position:fixed;
  bottom:10%;
}

/* ---------------------------------------- */
/* --------------- Homepage --------------- */
/* ---------------------------------------- */
.home-page-wrapper {
  color: #f2f9ff;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 15vw;
  padding-right: 15vw;
  width: 70vw;
}

.mobile-home-page-wrapper {
  color: #f2f9ff;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 90vw;
}

.home-page-content {
  margin-top:1vh;
}

.home-page-headshot {
  height:23vh;
  width:auto;
  border-radius:5%;
  float:left;
  margin-top:5px;
  margin-right:10px;
}

.home-page-wrapper::-webkit-scrollbar {
  display: none;
}

.home-page-title {
  text-shadow: 3px 3px 0 #7A7A7A;
  margin-bottom: 0;
}

.home-page-section-title {
  font-size:28px;
  text-shadow: 2px 2px 0 #7A7A7A;
}

.home-page-link {
  color: #f2f9ff;
}

.home-page-link:visited {
  color: #f2f9ff;
}

.home-page-section-text {
  font-size: 20px;
  text-shadow: 0.5px 0.5px 0 #7A7A7A;
}
/* ---------------------------------------- */
/* ------------- Projects Page ------------ */
/* ---------------------------------------- */
.projects-page-wrapper {
  color: #f2f9ff;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 15vw;
  padding-right: 15vw;
  width: 70vw;
}

.mobile-projects-page-wrapper {
  color: #f2f9ff;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 90vw;
}

.projects-page-title {
  text-shadow: 3px 3px 0 #7A7A7A;
}

.projects-page-wrapper::-webkit-scrollbar {
  display: none;
}

.project-wrapper {
  height:100%;
  margin:1%;
  border-radius:10px;
  background-color: #2182f1;
  transition: all 0.5s;
  -webkit-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
}

.project-wrapper:hover {
  transform: scale(1.02);
}

.project-link {
  color: #f2f9ff;
  width:100%;
  height:100%;
  text-decoration:none;
}

.project-link:visited {
  color: #f2f9ff;
}

.project-image {
  width:100%;
  height:auto;
  border-radius: 4px;
  border-style: solid;
  border-color: #56b9f3;
  border-width: 2px;
}

.projects-wrapper {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  width:100%;
}

.more-projects-soon {
  width:94%;
  background-color: #2182f1;
  text-align:center;
  padding:2%;
  border-radius:10px;
  margin-left:1%;
  margin-bottom:5vh;
}

.project-title {
  text-shadow: 1.5px 1.5px 0 #7A7A7A;
}

.project-text {
  font-size: 18px;
  text-shadow: 0.4px 0.4px 0 #7A7A7A;
}

/* ---------------------------------------- */
/* -------------- Resume Page ------------- */
/* ---------------------------------------- */
.resume-page-wrapper {
  color: #f2f9ff;
  height: 100%;
  overflow: auto;
  scroll-snap-type: block mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 15vw;
  padding-right: 15vw;
  width: 70vw;
}

.resume-page-wrapper::-webkit-scrollbar {
  display: none;
}

.experience-container {
  position:relative;
  height:92vh;
  width:80%;
  margin-left: 10%;
  text-align:center;
  scroll-snap-align:start;
}

.mobile-experience-container {
  position:relative;
  height:90vh;
  width:100%;
  text-align:center;
  scroll-snap-align:start;
}
  

.center-experience {
  position:absolute;
  top:50%;
  left:50%;
  margin-right:-50%;
  transform:translate(-50%, -50%);
  width: 100%;
}

.experience-title {
  font-size:60px;
  text-shadow: 4px 3px 0 #7A7A7A;
}

.experience-subtitle {
  font-size:34px;
  margin-top:10px;
  text-shadow: 2px 2px 0 #7A7A7A;
}

.experience-text {
  font-size: 20px;
  text-shadow: 1px 1px 0 #7A7A7A;
}

.pdf-text {
  font-size:26px;
  margin-top:10px;
  text-shadow: 2px 2px 0 #7A7A7A;
}

.mobile-experience-title {
  font-size: calc(24px + 2vmin);
  text-shadow: 3px 2px 0 #7A7A7A;
}

.mobile-experience-subtitle {
  font-size: calc(14px + 2vmin);
  margin-top:10px;
  text-shadow: 2px 1px 0 #7A7A7A;
}

.mobile-experience-text {
  font-size: calc(8px + 2vmin);
  text-shadow: 1px 1px 0 #7A7A7A;
}

.mobile-pdf-text {
  font-size: calc(12px + 2vmin);
  margin-top:10px;
  text-shadow: 2px 1px 0 #7A7A7A;
}

.resume-navigation-wrapper {
  position:fixed;
  right:3vw;
  top:35vh;
  z-index:125;
}

.experience-section-link {
  color:#f2f9ff;
  text-decoration: none;
}

.resume-page-title {
  position:fixed;
  top: 0;
  text-shadow: 3px 3px 0 #7A7A7A;
}

.resume-mobile-line {
  border: 1px solid #f2f9ff;
}

.resume-pdf-link {
  color: #f2f9ff;
}

.resume-pdf-link:visited {
  color: #f2f9ff;
}